@import "common.scss";

@font-face {
  font-family: "SF Pro";
  font-weight: 100;
  src: url("fonts/SF-Pro-Display-Ultralight.otf");
}

@font-face {
  font-family: "SF Pro";
  font-weight: 200;
  src: url("fonts/SF-Pro-Display-Light.otf");
}

@font-face {
  font-family: "SF Pro";
  font-weight: 300;
  src: url("fonts/SF-Pro-Display-Medium.otf");
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: SF Pro;
  font-weight: 100;
  text-align: center;
  background-color: #282c34;
}

.content {
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

input::-webkit-input-placeholder {
  font-family: SF Pro;
  font-weight: 100;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

.textInput {
  margin: 20px;
  background: transparent;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}

.hexLabel {
  margin: 12px;
  font-size: 1em;
  color: #fff;
}

.downbutton {
  width: 200px;
  height: 50px;
  color: #fff;
  background-color: $primaryColor;
  margin: 5px;
  font-size: 1.3em;
  font-weight: 200;
  text-decoration: none;
  line-height: 50px;
  border-radius: 10px;
  transition: 0.3s;
  &:hover,
  &:visited,
  &:active {
    background-color: shade($primaryColor);
    color: #ccc;
    text-decoration: none;
  }
}

.paletteWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
}

.huePicker {
  padding: 20px;
}
.editorWrapper {
  display: flex;
  flex-direction: row;
  width: 400px;
  margin: 8px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.editoritem {
  margin: 0px 5px 0px 5px;
}

.fontSelect {
  font-weight: 200;
}
