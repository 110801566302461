@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Nanum+Myeongjo);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+KR);
@import url(https://fonts.googleapis.com/css?family=Do+Hyeon);
@import url(https://fonts.googleapis.com/css?family=Yeon+Sung&subset=korean);
@font-face{font-family:"SF Pro";font-weight:100;src:url(/static/media/SF-Pro-Display-Ultralight.a534d575.otf)}@font-face{font-family:"SF Pro";font-weight:200;src:url(/static/media/SF-Pro-Display-Light.5c4d20e5.otf)}@font-face{font-family:"SF Pro";font-weight:300;src:url(/static/media/SF-Pro-Display-Medium.0541e229.otf)}html,body{width:100%;height:100%;margin:0}.App{min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-family:SF Pro;font-weight:100;text-align:center;background-color:#282c34}.content{flex-grow:1;display:flex;position:relative;flex-direction:column;align-items:center;justify-content:center;padding:20px}input::-webkit-input-placeholder{font-family:SF Pro;font-weight:100}input:focus::-webkit-input-placeholder{color:transparent}.textInput{margin:20px;background:transparent;border:0;outline:0;border-bottom:1px solid #fff;color:#fff;font-size:1.5em;text-align:center}.hexLabel{margin:12px;font-size:1em;color:#fff}.downbutton{width:200px;height:50px;color:#fff;background-color:salmon;margin:5px;font-size:1.3em;font-weight:200;text-decoration:none;line-height:50px;border-radius:10px;transition:0.3s}.downbutton:hover,.downbutton:visited,.downbutton:active{background-color:#af5a50;color:#ccc;text-decoration:none}.paletteWrapper{position:relative;display:flex;flex-direction:column;align-items:center;padding-top:5px;padding-bottom:10px}.huePicker{padding:20px}.editorWrapper{display:flex;flex-direction:row;width:400px;margin:8px;padding:5px;justify-content:center;align-items:center}.editoritem{margin:0px 5px 0px 5px}.fontSelect{font-weight:200}

.previewCanvas{margin:10px;background-color:#fff}

.sidetab{position:fixed;left:0;top:0;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:#393f4c;width:50px;height:100%;z-index:100}.sidePane{display:flex;flex-direction:column;align-items:center;justify-content:center;color:#fff;border-left:1px solid #fff;position:fixed;left:50px;top:0;background-color:#393f4c;width:200px;height:100%}

.sideItem{margin:7px 0px 7px 0px}

.ThumbnailList_thumbnail__item__-4Lic{height:150px}.ThumbnailList_thumbnail__item__-4Lic img{max-height:120px;width:100%;display:block;margin:0 auto;border:5px solid #ffffff;cursor:pointer}.ThumbnailList_thumbnail__item__-4Lic img.ThumbnailList_active__2wp8N{border:5px solid #5a6dff}

.SearchForm_searchForm__284q9{display:flex;padding-bottom:10px}.SearchForm_searchForm__284q9 .SearchForm_searchForm__query__3aDOt{flex:2 1;margin-right:10px}

.Loading_loading__3165N{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);z-index:99;padding:15px;border-radius:5px;background:#000000d1;color:white}.Loading_loading__3165N .Loading_loading__text__XH9ve{margin-left:10px}

